@import '../../styles/variables';

.register {

  min-height: 0 !important;


    @media only screen and (min-width: 600px) {
      .register-left {
        margin-top: 10% !important;
        margin-bottom: auto !important;
      }
    }

  .register-left {
    display: flex;
    flex-direction: column;

    .logo-wrapper {
      margin-bottom: 21px;
    }

    button:not(:last-child) {
      margin-bottom: 29px;
    }

    .main-title {
      margin-top: 10px;
      margin-bottom: 1px;
      color: white;
      font-size: 30px;
    }
  }

  .form {
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    margin-bottom: -150px;
  }

  @media only screen and (min-width: 600px) {
    .register-right {
      margin-top: 10% !important;
      margin-left: 60px !important;
      margin-bottom: 0px !important;
    }
  }

  .register-right {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    margin-bottom: 30px;

    .main-subtitle {
      margin-bottom: 62px;
    }

    img {
      width: 100%;
      border-radius: 30px;
      z-index: 1;
      object-fit: contain;
      object-position: 0px 0px;
    }

    .waves-container {
      z-index: 0;
    }
  }

  .invalid-credentials{
    height: 48px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #301a1e;
    border: solid 1px #4a2124;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 15px;
  }

  .invalid-credentials-text{
    color: white;
    align-self: center;
    font-size: 14px;
  }

  .cross{
    cursor: pointer;
    color: #a52a2e;
    align-self: center;
    font-size: 14px;
  }
}
