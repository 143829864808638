@import '../../styles/variables';

.verify {
  background-color: $verifyBG;
  display: flex;
  align-items: center;
  justify-content: center;

  .center-container {
    .logo-wrapper {
      margin-bottom: 21px;
    }

    .title {
      margin-bottom: 48px;
    }

    .inputs {
      margin-bottom: 2px;
    }

    button:not(:last-child) {
      margin-bottom: 23px;
    }
  }

  .waves-container {
    z-index: 0;
  }
}
