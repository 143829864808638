$mainFont: "Open Sans", sans-serif;

$white: #fff;
$black: #000;
$logoColor: #ffd149;
$dark: #2c2a35;
$inputBG: #f7f7f7;
$inputError: #ff0000;
$inputBorderFocus: #ffd149;
$buttonYellowBG: #ffd149;
$buttonGreyBorder: #e9e9e9;
$registerBG: #ffe46c;
$loginBG: #ffe46c;
$verifyBG: #ffe46c;
