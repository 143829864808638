@import '../../styles/variables';

.reset {
  background-color: $verifyBG;
  display: flex;
  align-items: center;
  justify-content: center;

  .center-container {
    .logo-wrapper {
      margin-bottom: 21px;
    }

    .title {
      margin-bottom: 15px;
    }

    .inputs {
      margin-bottom: 2px;
    }

    button:not(:last-child) {
      margin-bottom: 23px;
    }
  }

  .waves-container {
    z-index: 0;
  }

  .success_email_send{
    text-align: center;
  }

  .invalid-credentials-block{
    width: 311px;
  }

  .invalid-credentials{
    height: 48px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #301a1e;
    border: solid 1px #4a2124;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 15px;
  }

  .invalid-credentials-text{
    color: white;
    align-self: center;
    font-size: 14px;
  }

  .cross{
    cursor: pointer;
    color: #a52a2e;
    align-self: center;
    font-size: 14px;
  }
}
