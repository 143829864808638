
@import '../../styles/variables';

.login-index {
  @import '../../styles/_login-index.scss';
}

.login {

  @media only screen and (max-width: 600px) {
    .login-left {
      display: none !important;
      padding-bottom: 0px !important;
    }

    .login-right {
      padding-bottom: 0px !important;
      width: 100% !important;
    }
  }

  .login-left {
    display: flex;
    flex-direction: column;
    background-color: $loginBG;

    .container {
      width: 537px;
    }

    .main-title {
      margin-bottom: 14px;
      font-size: 22px;
    }

    .main-subtitle {
      margin-bottom: 56px;
    }

    .p1-img {
      width: 537px;
      height: 302px;
      box-shadow: 0 2px 7px 0 rgba(192, 192, 192, 0.5);
    }
  }

  .login-right {
    display: flex;
    flex-direction: column;

    .logo-wrapper {
      margin-bottom: 21px;
    }

    button:not(:last-child) {
      margin-bottom: 29px;
    }

    .forgot-pass {
      text-align: center;
      cursor: pointer;
      color: white;
    }
  }

  .invalid-credentials{
    height: 48px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #301a1e;
    border: solid 1px #4a2124;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 15px;
  }

  .invalid-credentials-text{
    color: white;
    align-self: center;
    font-size: 14px;
  }

  .cross{
    cursor: pointer;
    color: #a52a2e;
    align-self: center;
    font-size: 14px;
  }
}
