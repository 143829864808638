.waves-container {
  position: relative;
  //left: 0;
  bottom: 0;
  width: 100%;
  height: 189px;
  overflow: hidden;
  z-index: -1;
  //margin-bottom: 0px;

  svg {
    width: 100%;
    height: 100%;

    path {
      stroke: none;
    }

    #gradient-one {
      --color-top: rgba(255, 248, 215, .25);
      --color-bottom: rgba(255, 255, 255, .25);
    }

    #gradient-two {
      --color-top: rgba(255, 241, 180, .61);
      --color-bottom: rgba(255, 255, 255, .61);
    }

    #gradient-three {
      --color-top: rgba(255, 232, 124, .59);
      --color-bottom: rgba(255, 255, 255, .59)
    }

    .one {
      fill: url(#gradient-one);
    }

    .two {
      fill: url(#gradient-two);
    }

    .three {
      fill: url(#gradient-three);
    }

    .four {
      fill: rgba(254, 229, 124, .9);
    }
  }
}
