@import "variables";

.page {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  font-family: $mainFont;
  position: relative;

  &.row {
    display: flex;
    flex-direction: row;
  }

  &.column {
    display: flex;
    flex-direction: column;
  }

  &-left, &-right {
    width: 50%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
    position: relative;
  }

  .logo-wrapper {
    display: flex;
    align-items: center;

    .logo-cloud {
      height: 32px;
    }

    .logo-text {
      font-size: 28px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.25px;
      color: $logoColor;
      margin-left: 5px;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    color: $dark;
    width: 311px;

    .title {
      font-size: 30px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: -0.23px;
      text-align: center;
      margin-bottom: 4px;
    }

    .subtitle {
      font-size: 15px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: -0.32px;
      text-align: center;
      margin-bottom: 21px;
    }

    .inputs {
      display: flex;
      flex-direction: column;
      margin-bottom: 17px;

      .input-wrapper {
        position: relative;

        .input {
          font-family: $mainFont;
          width: 100%;
          height: 48px;
          border-radius: 24px;
          background-color: $inputBG;
          border: 1px solid $inputBG;
          padding: 0 14px;
          font-size: 15px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.28px;
          margin-bottom: 20px;
          transition: all .4s ease-in-out;

          &:focus {
            border-color: $inputBorderFocus;
          }

          &.valid {

          }

          &.invalid {
            border-color: $inputError
          }

          &.mb-55 {
            margin-bottom: 55px;
          }

          &-error {
            display: none;
            position: absolute;
            left: 15px;
            top: 49px;
            font-size: 14px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: -0.32px;
            color: $inputError;

            &.invalid {
              display: block;
            }
          }
        }
      }
    }

    button {
      width: 100%;
      height: 44px;
      border: none;
      border-radius: 24px;
      cursor: pointer;
      transition: all .4s ease-in-out;
      font-family: $mainFont;
      font-size: 17px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.3px;
      text-align: center;

      &.yellow {
        background-color: $buttonYellowBG;
        color: $white;
      }

      &.white {
        background-color: $white;
        color: $buttonYellowBG;
        border: 1px solid $buttonGreyBorder
      }

      &:hover {
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }

  .main-title {
    font-family: $mainFont;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.87px;
    text-align: center;
    color: $dark;
  }

  .main-subtitle {
    font-family: $mainFont;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.52px;
    text-align: center;
    color: $dark;
  }

  .center-container {
    width: 597px;
    height: auto;
    padding: 50px;
    border-radius: 12px;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0.92), $white 36%);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;

    .title {
      font-family: $mainFont;
      font-size: 30px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: -0.23px;
      text-align: center;
      color: $dark;
    }
  }
}
